import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// külső lib importok
import { NgxPanZoomModule } from 'ngx-panzoom';

// default component és environment
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';

import { 
  Nyomda, 
  OAuth2Client,
  OAuth2User,
  UserProfile,
  TokenEntity
} from './core/entity/core/auth';

import {
  TranslateProject,
  TranslateInstance,
  TranslateNode
} from './core/entity/core/translate';

import { 
  Diszpo,
  Nev,
  Partner, 
  PartnerCim, 
  ScrollFile,
  Szallito,
  Termekdij
} from './core/entity/scroll';

import { 
  SpSoftproof, 
  SpSzamla, 
  SpSzerszam, 
  SpTaska, 
  SpTaskaDetails, 
  SpDiszpo, 
  SpTaskaFile, 
  SpLevel,
  SpLevelsor,
  SpUzenetFej,
  SpUzenet,
  SpLevelKerdes
} from './core/entity/printportal';

// core service-k
import { CoreResponse, EntityService } from '@scrollmax/core-nextgen';
import { CoreTranslateService, CORE_TRANSLATE_CONFIG_TOKEN, CoreTranslateModule } from '@scrollmax/core-nextgen/translate';

// saját service-k, guardok, stb
import { AppLog } from './core/util/log.util';
import { AuthService } from './core/service/auth.service';
import { PusherService } from './core/service/pusher.service';
import { AuthGuard } from './core/guards/auth.guard';
import { PrintportalPreloadResolver } from './core/resolvers/preload-resolver';
import { AuthInterceptorProviders } from './core/interceptors/token-interceptor';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';


function appInitializer(
  authService: AuthService, 
  entityService: EntityService
  ) {

  return () => {
    return new Promise((resolve) => {

      // scroll auth registrations
      entityService.register(Nyomda);
      entityService.register(OAuth2Client);
      entityService.register(OAuth2User);
      entityService.register(UserProfile);
      entityService.register(TokenEntity);

      entityService.register(TranslateNode);
      entityService.register(TranslateInstance);
      entityService.register(TranslateProject);

      // printportal "saját" entitás regisztrációk

      // printportal "sp" entitás regisztrációk
      entityService.register(SpTaska);
      entityService.register(SpTaskaDetails);
      entityService.register(SpTaskaFile);
      entityService.register(SpSzamla);
      entityService.register(SpDiszpo);
      entityService.register(SpSoftproof);
      entityService.register(SpSzerszam);
      entityService.register(SpLevel);
      entityService.register(SpLevelsor);
      entityService.register(SpLevelKerdes);
      entityService.register(SpUzenetFej);
      entityService.register(SpUzenet);
      
      // scroll entitás regisztrációk
      entityService.register(ScrollFile);
      entityService.register(Partner);
      entityService.register(PartnerCim);
      entityService.register(Nev);
      entityService.register(Diszpo);
      entityService.register(Szallito);
      entityService.register(Termekdij);

      AppLog.log('Entities registered successfully', entityService.list());

      resolve(authService.observeProfile().subscribe({ 
        complete: () => {
          AppLog.log('Trying to get user profile');
          return resolve;
        },
        error: () => {
          AppLog.log('User profile did not fetched, maybe a token issue');
          return resolve;
        }   
      }));         
      
    }).catch(console.log);
  };
}

/**
 * Ezt a funkciót egy APP_INITIALIZER tokennel hívjuk, amely még a teljes inicializáció előtt meg fogja hívni 
 * a translate végpontot és betölti a projekthez aktuálisan beállított (environment-ben) nyelvi állományt.
 * 
 * @param translateService 
 */
export function initializeTranslate(translateService: CoreTranslateService) {
  return (): Promise<any> => {
    return translateService.init();
  };
}

export function initEnvironment(): any {
  AppLog.message('Init current environment: ' + environment.name);
  return environment;
}

const routes: Routes = [
  {
    path: 'activation/:slug',
    redirectTo: 'auth/activation/:slug'
  },
  {
    path: 'change-password/:slug',
    redirectTo: 'auth/change-password/:slug'
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    resolve: {
      data: PrintportalPreloadResolver
    },
    loadChildren: () => import('./modules/portal/portal.module').then((m) => m.PortalModule),
  },
  { path: '**', redirectTo: 'errors/404', pathMatch: 'full' },
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, { anchorScrolling: 'enabled' }),
    CoreTranslateModule.forRoot({
      // tslint:disable-next-line:object-literal-shorthand
      environment: environment
    }),
    NgxPanZoomModule,
    NgbDatepickerModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot({})
  ],
  providers: [
      // provideClientHydration(),
      provideHttpClient(withInterceptorsFromDi()),
      {
        provide: APP_INITIALIZER,
        useFactory: appInitializer,
        deps: [AuthService, EntityService],
        multi: true
      },
      {
        provide: APP_INITIALIZER,
        useFactory: initializeTranslate,
        deps: [CoreTranslateService],
        multi: true
      },
      {
        provide: CORE_TRANSLATE_CONFIG_TOKEN,
        useFactory: initEnvironment
      },
      AuthInterceptorProviders
  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
