import { AfterViewInit, Component, HostBinding, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import KTComponents from '../metronic/core/index';
import KTLayout from '../metronic/app/layouts/index';
import { filter } from 'rxjs';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss'
})
export class AppComponent implements AfterViewInit, OnInit {
	
	preload = 'navigation_start';

	// @HostBinding('class') hostClass = 'flex grow';

	constructor(private router: Router) {}

	ngAfterViewInit(): void {
		KTComponents.init();
		KTLayout.init();
	}

	ngOnInit(): void {

		this.router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
			this.preload = 'navigation_end';	
		});

		this.router.events.pipe(filter((e): e is NavigationStart => e instanceof NavigationStart)).subscribe((e: NavigationStart) => {
			this.preload = 'navigation_start';
		});
		
	}
}

