@switch (preload) {
    @case ('navigation_end') {
		<ng-container>
			<router-outlet></router-outlet>
		</ng-container>
    }
	@case ('navigation_start') {
		<ng-container>
			<div class="flex items-center justify-center h-screen w-screen">
				<div class="relative flex justify-center items-center">
					<div class="absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-blue-500"></div>
					<img src="assets/scroll-sm.png"  class="rounded-full">
				</div>
			</div>
		</ng-container>
	}
}
