import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { map, take } from "rxjs/operators";

import { CoreResponse } from '@scrollmax/core-nextgen';
import { PreloadEntity } from "../entity/core/preload.entity";
import { environment } from "../../../environments/environment";
import { CoreService } from "../service/core.service";

@Injectable({ providedIn: 'root' })
export class PrintportalPreloadResolver implements Resolve<CoreResponse> {

  constructor(private http:HttpClient, private coreService: CoreService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {

    if (this.coreService.getNyomdaId()) {
      
      return this.http.get(`${environment.endpoints.app}/pp_preload_v2/${this.coreService.getNyomdaId()}`)
      .pipe(map((r) => { 
        
          this.coreService.setPreloadEntity(r['data'].payload as PreloadEntity);
  
        return r['data'].payload as PreloadEntity;
      }));

    } else {
      return of(new PreloadEntity());
    }

  }
}